import { CircularFillCheckmarkIcon as Checkmark } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';

import { Image } from '@/app/components/ui';
import { optionButton } from '@/app/styles/ui';

export interface FinishOptionsProps {
  readonly onChange: (value: string) => void;
  readonly options: {
    imgSrc: string;
    label: string;
    value: string;
  }[];
  readonly selected: string;
}

export const FinishOptions = ({
  onChange,
  options,
  selected,
}: FinishOptionsProps): JSX.Element => {
  return (
    <ul
      aria-labelledby="finish-options-heading"
      aria-required="true"
      className="flex flex-wrap gap-4"
      role="radiogroup"
      data-testid="finish-options"
    >
      {options.map(({ imgSrc, label, value }) => {
        const checked = selected === value;

        return (
          <li key={value} className="h-16 w-full grow lg:w-1">
            <label
              className={cx(optionButton({ checked }), 'pr-6 flex gap-2', {
                'pl-8': !imgSrc,
              })}
            >
              <input
                checked={checked}
                className="sr-only"
                name="finish"
                onChange={() => onChange(value)}
                type="radio"
                value={value}
              />

              {imgSrc && (
                <Image
                  className="h-full w-auto"
                  height="64"
                  priority
                  src={imgSrc}
                  width="80"
                />
              )}

              {label}

              {checked && (
                <span className="ml-auto">
                  <Checkmark />
                </span>
              )}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default FinishOptions;
