'use client';

import { TypographyV3 as Typography } from '@jouzen/ecom-components';
import { useEffect } from 'react';

import { EmailLayout } from '@/app/components/layout';
import { useAppDispatch } from '@/lib/hooks';
import { resetOrder } from '@/lib/order/orderSlice';

import { EssenceHelpLink } from '../components';

const Content = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetOrder());
  }, [dispatch]);

  return (
    <EmailLayout
      title="Get Started with Oura"
      description="Please enter the email associated with your Essence Healthcare account to receive a sign-in code."
      help={
        <Typography className="flex flex-wrap gap-x-1.5">
          <span>Need help?</span>
          <span>
            Email <EssenceHelpLink />
          </span>
        </Typography>
      }
      imageSrc="/eop/woman-long-hair.jpg"
      partner="essence"
      isInvalid={false}
      isMaintenance={false}
    />
  );
};

export default Content;
