import getAnonymousId from './getAnonymousId';
import type { MiddlewareFunction, SegmentEvent, SegmentFacade } from './types';

const withAnonymousId: MiddlewareFunction = ({ payload, next }): void => {
  const anonymousId = getAnonymousId();

  const obj: SegmentEvent = {
    ...payload.obj,
    anonymousId,
  };

  next({ ...payload, obj } as SegmentFacade);
};

export default withAnonymousId;
