import { emailAdded, orderAdded } from '@/lib/order/orderSlice';
import { Address } from '@/types';
import { getCookie } from '@/utils/cookie';
import { logToDataDog } from '@/utils/logToDatadog';
import { ParsedSku } from '@/utils/parseSku';

import { mocks } from '../../../mocks/apiMocks';
import {
  LOGIN_METRIC_NAME,
  MetricStep,
} from '../components/ui/EmailForm/const';
import { getEmailToken } from './emailTokenHelper';
import { validateEmail, ValidateEmailResponse } from './validateEmail';

interface HandleEmailValidationParams {
  email: string;
  partnerConfig: {
    validateUser: boolean;
    completedStep: string;
    successStep: string;
    invalidStep: string;
  };
  metricIncrement: (
    name: string,
    options: { step: MetricStep; partner: string },
  ) => Promise<void>;
  dispatch: (action: any) => void;
  push: (path: string) => void;
  partner: string;
}

export async function handleEmailValidation({
  email,
  partnerConfig,
  metricIncrement,
  dispatch,
  push,
  partner,
}: HandleEmailValidationParams) {
  const emailToken = getEmailToken();
  const MOCK_DATA = getCookie('mock')?.toUpperCase();
  if (partnerConfig.validateUser) {
    const response: ValidateEmailResponse = MOCK_DATA
      ? mocks[MOCK_DATA].email
      : await validateEmail(email, emailToken);

    if (
      response?.eligible &&
      response?.netsuiteInternalId &&
      response?.shippingAddress
    ) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_SUCCESS,
        partner,
      });
      dispatch(
        orderAdded({
          email,
          netsuiteOrderId: response.netsuiteOrderId! ?? null,
          netsuiteInternalId: response.netsuiteInternalId!,
          shippingAddress: response.shippingAddress as Address,
          selectedSku:
            response.orderComplete && response.skus?.length
              ? new ParsedSku(response.skus[0]!).withoutEnterpriseCode()
              : '',
          agreesToShare: response.orderComplete ? false : undefined,
          orderComplete: response.orderComplete,
        }),
      );
      if (response?.orderComplete) push(partnerConfig.completedStep);
      else push(partnerConfig.successStep);
    } else {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP Email Validation error', {
        partner,
        emailToken: emailToken,
        error: response.error ?? null,
      });
      push(partnerConfig.invalidStep);
    }
  } else {
    dispatch(emailAdded(email));
    push(partnerConfig.successStep);
  }
}
