import { env } from 'next-runtime-env';

import { readFromLocalStorage } from './localStorage';
import { metricError } from './reportMetrics';

export interface DebugAnalyticsOptions {
  data: { [key: string]: any };
  exception: boolean; // log to datadog as "exception" (rather than "error")
  level: 'log' | 'warn' | 'error'; // console level
  logToDatadog: string | undefined | null; // id to use for datadog logs
}

const DEFAULT_OPTIONS: DebugAnalyticsOptions = {
  data: {},
  exception: false,
  level: 'log',
  logToDatadog: null,
};

export function debugAnalytics(
  message: string,
  options: Partial<DebugAnalyticsOptions> = {},
) {
  const {
    data,
    exception: isException,
    level,
    logToDatadog: logId,
  } = { ...DEFAULT_OPTIONS, ...options };

  const isDebugging =
    env('NEXT_PUBLIC_DEBUG_ANALYTICS') === 'true' ||
    readFromLocalStorage('eop-debug-analytics');

  if (isDebugging && level && console[level]) {
    console[level](`[A] ${message}`, data);
  }

  if (!isDebugging && Boolean(logId)) {
    metricError('analytics', {
      ...data,
      id: logId,
      message,
      bucket: 'eop-analytics',
      category: isException ? 'exception' : 'error',
    });
  }
}
