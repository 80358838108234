'use client';

import { env } from 'next-runtime-env';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import {
  type StatsigOptions,
  StatsigProvider as StatsigAsyncProvider,
  StatsigSynchronousProvider,
  type StatsigUser,
} from 'statsig-react';

interface StatsigClientProviderProps extends PropsWithChildren {
  readonly user: StatsigUser;
  readonly data?: Record<string, unknown>;
  readonly optionOverrides?: StatsigOptions;
}

export default function StatsigProvider({
  user,
  data,
  children,
  // stableId,
  optionOverrides,
}: StatsigClientProviderProps): JSX.Element {
  const NEXT_PUBLIC_STATSIG_CLIENT_KEY = env('NEXT_PUBLIC_STATSIG_CLIENT_KEY');
  const NEXT_PUBLIC_OURA_ENV = env('NEXT_PUBLIC_OURA_ENV');

  const stableId = user.customIDs?.['stableID'];

  const options: StatsigOptions = {
    disableAutoMetricsLogging: true,
    disableErrorLogging: true,
    disableLocalOverrides: NEXT_PUBLIC_OURA_ENV === 'production',
    environment: { tier: `${NEXT_PUBLIC_OURA_ENV}` },
    // Override the stable id using the id generated on the server with the initial request in middleware.ts
    // This ensures that the stable id is the same for both the server and client
    // https://docs.statsig.com/client/javascript-sdk/#keeping-stableid-consistent-across-client--server
    ...(stableId && { overrideStableID: stableId }),
    ...optionOverrides,
  };

  // No statsig values loaded on the server, so we need to wait for the SDK to initialize
  if (data == null) {
    return (
      <StatsigAsyncProvider
        sdkKey={NEXT_PUBLIC_STATSIG_CLIENT_KEY!}
        user={user}
        waitForInitialization={true}
        options={options}
      >
        {children}
      </StatsigAsyncProvider>
    );
  }
  return (
    <StatsigSynchronousProvider
      sdkKey={NEXT_PUBLIC_STATSIG_CLIENT_KEY!}
      user={user}
      initializeValues={data}
      options={options}
    >
      {children}
    </StatsigSynchronousProvider>
  );
}
