'use client';

import { UiProvider as Provider } from '@jouzen/ecom-components';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import type { PropsWithChildren } from 'react';

import { useCountry } from '@/queries/Countries';

export interface UiProviderProps extends PropsWithChildren {
  readonly locale: string;
}

function UiProvider({ children, locale }: UiProviderProps): JSX.Element {
  const { data: countryData } = useCountry('US');
  const t = useTranslations();
  return (
    <Provider
      //@ts-expect-error is nullable type
      LinkComponent={Link}
      currencyProps={{
        country: countryData?.countryCode ?? 'US',
        fallbackCurrencyCode: 'USD',
        fallbackLocale: locale,
        freeMessage: t('cart_free'),
        locale,
      }}
      intlProps={{ locale }}
    >
      {children}
    </Provider>
  );
}

export default UiProvider;
