'use client';

import {
  GridItem,
  LayoutGrid,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { type ReactNode, useEffect, useMemo, useState } from 'react';

import { EventType } from '@/analytics/types';
import { Note, SectionHeading, SubmitButton } from '@/app/components/ui';
import {
  findProductDataBySku,
  getProductFinish,
  getProductVariantSize,
} from '@/app/utils';
import { useRouter } from '@/i18n/routing';
import { useAppDispatch } from '@/lib/hooks';
import { skuSelected } from '@/lib/order/orderSlice';
import type { Product } from '@/queries/types/graphql';
import type { StepConfig } from '@/types';

import PageHeader, { type PageHeaderProps } from '../PageHeader';
import {
  FinishOptions,
  type FinishOptionsProps,
  ProductCarousel,
  type ProductCarouselImage,
  SizeGuide,
  SizeOptions,
  type SizeOptionsProps,
} from './components';

const INIT = {
  FINISH: '',
  SIZE: '',
  SIZE_ERROR: '',
};

interface ContentProps {
  readonly eyebrow: PageHeaderProps['eyebrow'];
  readonly nextStep: StepConfig;
  readonly note?: PageHeaderProps['note'];
  readonly products: Product[];
  readonly selectedSku?: string;
  readonly submitText: ReactNode;
  readonly title: PageHeaderProps['title'];
}

export const ProductSelect = ({
  eyebrow,
  nextStep,
  note,
  products,
  selectedSku: initSelectedSku,
  submitText,
  title,
}: ContentProps): JSX.Element => {
  const { push } = useRouter();
  const dispatch = useAppDispatch();

  const { finish: initFinish = INIT.FINISH, size: initSize = INIT.SIZE } =
    findProductDataBySku(products, initSelectedSku!);

  const [selectedFinish, setSelectedFinish] = useState<string>(initFinish);
  const [selectedSize, setSelectedSize] = useState<string>(initSize);
  const [sizeError, setSizeError] = useState<string>(INIT.SIZE_ERROR);

  const selectedProduct = products.find(
    (product) => getProductFinish(product) === selectedFinish,
  );

  const finishOptions = products.reduce(
    (list, product) => {
      const finish = getProductFinish(product);
      if (!finish) return list;

      return [
        ...list,
        {
          imgSrc: product.wizard?.finishStep?.selector?.originalSrc ?? '',
          label: finish,
          value: finish,
        },
      ];
    },
    [] as FinishOptionsProps['options'],
  );

  useEffect(() => {
    // set initial finish option (once products load)
    if (
      finishOptions.length &&
      !finishOptions.some(({ value }) => value === selectedFinish)
    ) {
      const finish = finishOptions.some(({ value }) => value === INIT.FINISH)
        ? INIT.FINISH
        : finishOptions[0].value;
      setSelectedFinish(finish);
    }
  }, [finishOptions, selectedFinish]);

  const sizeOptions = selectedProduct?.variants
    ?.map((variant) => getProductVariantSize(variant!))
    // filter out any bad sizes
    .filter((size) => size && !['Size later'].includes(size))
    .map((size) => ({
      value: size,
      label: size?.startsWith('0') ? size.replace('0', '') : size,
    })) as SizeOptionsProps['options'];

  useEffect(() => {
    // reset selected size when not found in list of options
    if (!sizeOptions?.some(({ value }) => value === selectedSize)) {
      setSelectedSize(INIT.SIZE);
    }
  }, [selectedSize, sizeOptions]);

  const images = useMemo(
    () => selectedProduct?.images as ProductCarouselImage[],
    [selectedProduct?.images],
  );

  const trackSubmitClick = async (): Promise<void> => {
    await window.analytics.track(EventType.CTAClicked, {
      cta: 'next: data sharing',
      action: 'submit size',
      location: 'body',
      path: '/select',
    });
  };

  const handleSubmitClick = async () => {
    const selectedSku = selectedProduct?.variants?.find(
      (variant) => getProductVariantSize(variant!) === selectedSize,
    )?.skuCode;

    await trackSubmitClick();

    if (selectedSku) {
      dispatch(skuSelected(selectedSku));
      push(nextStep.path);
    } else {
      setSizeError('Please select a ring size');
    }
  };

  const handleChangeFinish = (finish: string) => {
    setSelectedFinish(finish);
  };

  const handleChangeSize = (sku: string) => {
    setSelectedSize(sku);
    setSizeError(INIT.SIZE_ERROR);
  };

  return (
    <LayoutGrid rowGap={24} className="md:pt-12 lg:pt-20">
      <GridItem
        colEnd={{ sm: 'main', xl: 23 }}
        colStart={{ sm: 'main', md: 13 }}
        rowEnd={{ sm: 3, md: 2 }}
        rowStart={{ sm: 2, md: 1 }}
      >
        <PageHeader
          className="mb-2"
          eyebrow={eyebrow}
          title={title}
          note={note}
        />
      </GridItem>

      <GridItem
        colEnd={{ sm: 'full', md: 12 }}
        colStart={{ sm: 'full', md: 'main', xl: 3 }}
        rowEnd={{ sm: 2, md: 3 }}
        rowStart={{ sm: 1 }}
      >
        <ProductCarousel images={images} />
      </GridItem>

      <GridItem
        className="flex flex-col gap-8"
        colEnd={{ sm: 'main', xl: 23 }}
        colStart={{ sm: 'main', md: 13 }}
        rowEnd={{ sm: 4, md: 3 }}
        rowStart={{ sm: 3, md: 2 }}
      >
        <section className="flex flex-col gap-4">
          <SectionHeading id="finish-options-heading">
            Choose your finish
          </SectionHeading>

          <FinishOptions
            onChange={handleChangeFinish}
            options={finishOptions}
            selected={selectedFinish}
          />
        </section>

        <section className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div>
              <SectionHeading id="size-options-heading">
                Choose your size
              </SectionHeading>

              {sizeError && (
                <Typography color="error" variant="body-small">
                  {sizeError}
                </Typography>
              )}
            </div>

            <Note>
              Your Oura Ring sizing kit will help you select a size that ensures
              optimal accuracy and comfort day and night.
            </Note>

            <SizeGuide />
          </div>

          <SizeOptions
            error={sizeError}
            onChange={handleChangeSize}
            options={sizeOptions}
            selected={selectedSize}
          />
        </section>

        <SubmitButton onClick={handleSubmitClick}>{submitText}</SubmitButton>
      </GridItem>
    </LayoutGrid>
  );
};

export default ProductSelect;
