'use client';

import { useEffect } from 'react';

import setupAnalytics from '@/analytics/setupAnalytics';
import { debugAnalytics } from '@/utils/debugAnalytics';

/**
 * Taken from Consent Manager
 * https://github.com/jouzen/consent-manager/blob/main/src/hooks/useSegmentMiddleware.tsx
 *
 * This initializes the global Segment middleware.
 * Two ways of initializing middlewares are needed because
 * any middleware created before segment is ready is not applied to events fired after.
 * But events that are fired before the ready event will be fired
 * without middleware applied if we don't immediately initialize the middleware.
 */

const logError = (error: unknown) => {
  debugAnalytics(`Could not setup analytics`, {
    data: { error },
    exception: true,
    level: 'error',
    logToDatadog: 'useAnalytics',
  });
};

const useAnalytics = () => {
  useEffect(() => {
    void (async () => {
      try {
        await waitFor(() => window.analytics !== undefined);
        window.analytics.ready(() => setupAnalytics());
      } catch (error) {
        logError(error);
      }
    })();
  }, []);

  // Applies middleware to events fired before segment is "ready".  These middleware are removed after segment is ready
  useEffect(() => {
    void (async () => {
      try {
        await waitFor(
          () => window.analytics?.addSourceMiddleware !== undefined,
        );
        setupAnalytics();
      } catch (error) {
        logError(error);
      }
    })();
  }, []);
};

export default useAnalytics;

function waitFor(predicate: () => boolean, timeout = 1000): Promise<void> {
  return new Promise((resolve, reject) => {
    const evaluate = () => {
      if (!predicate()) return;
      clearInterval(interval);
      resolve();
    };

    const interval = setInterval(evaluate, 100);
    evaluate();

    if (!timeout) return;

    setTimeout(() => {
      clearInterval(interval);
      reject();
    }, timeout);
  });
}
