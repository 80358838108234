import { env } from 'next-runtime-env';

/**
 * Don't send OTP emails (or require OTP) for non ouraring.com emails
 * in local, sandbox, or staging.
 * This will help ensure we don't accidentally send OTP emails
 * to external email addresses in our lower envs.
 */

export const OURA_EMAIL = 'ouraring.com';

export const shouldAuthenticate = (email: string | null) => {
  // send OTP for everyone in prod
  if (env('NEXT_PUBLIC_OURA_ENV') === 'production') {
    return true;
  }

  // for testing ???
  if (!email) {
    return true;
  }

  // send OTP for oura email
  if (email?.includes(OURA_EMAIL)) {
    return true;
  }

  return false;
};
