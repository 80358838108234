import { SubmitOrderResponse } from '@/app/utils/submitOrder';
import { ValidateEmailResponse } from '@/app/utils/validateEmail';

type Mock = { email: ValidateEmailResponse; submit: SubmitOrderResponse };

const validateEmailMocks = {
  VALID_USER: {
    netsuiteOrderId: 'SO-123456',
    netsuiteInternalId: '1234567',
    shippingAddress: {
      address: '123 Test St',
      city: 'Austin',
      state: 'TX',
      postal: '78721',
      country: 'United States',
      fname: 'Oura',
      lname: 'Test',
    },
    emailToken: 'test-email-token',
    orderComplete: false,
    eligible: true,
  },
  INVALID_USER: {
    error: { message: 'error' },
  },
  ORDER_COMPLETED: {
    netsuiteOrderId: 'SO-123456',
    netsuiteInternalId: '1234567',
    shippingAddress: {
      address: '123 Test St',
      city: 'Austin',
      state: 'TX',
      postal: '78721',
      country: 'United States',
      fname: 'Oura',
      lname: 'Test',
    },
    skus: ['NT-JZ90-54144-06'],
    emailToken: 'test-email-token',
    orderComplete: true,
    eligible: true,
  },
};

const submitMocks = {
  SUBMIT_SUCCESS: {
    status: 'success',
    error: null,
  },
  SUBMIT_ERROR: {
    error: { message: 'Error' },
  },
};

export const mocks: Record<string, Mock> = {
  HAPPY_PATH: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_SUCCESS,
  },
  INVALID_USER: {
    email: validateEmailMocks.INVALID_USER,
    submit: submitMocks.SUBMIT_ERROR,
  },
  SUBMIT_ERROR: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_ERROR,
  },
  ORDER_COMPLETE: {
    email: validateEmailMocks.ORDER_COMPLETED,
    submit: submitMocks.SUBMIT_SUCCESS,
  },
};
