import { debugAnalytics } from '@/utils/debugAnalytics';

import type { MiddlewareFunction } from './types';

const withEventTypes: MiddlewareFunction = ({ payload, next }): void => {
  const eventType = payload.obj.type;

  switch (eventType) {
    case 'page':
      debugAnalytics(`Sending page event`, { data: { payload } });
      next(payload);
      break;

    case 'track':
      debugAnalytics(`Sending track event`, { data: payload });
      next(payload);
      break;

    /**
     * By definition, we want to avoid identify events
     * because we don't want to collect identifying information.
     * However we need to let the initial identify event
     * from Consent Manager pass thru to Segment.
     */
    case 'identify':
      debugAnalytics(`Sending identify event`, {
        data: payload,
        level: 'warn',
      });
      next(payload);
      break;

    default:
      debugAnalytics(`Did not send ${eventType} event`, {
        data: payload,
        level: 'warn',
        logToDatadog: 'withEventTypes',
      });
  }
};

export default withEventTypes;
