'use client';

import { Loading } from '@jouzen/ecom-components';
import { PropsWithChildren, ReactNode } from 'react';
import { useGate } from 'statsig-react';

import { useAnalytics } from '@/app/hooks';

import MaintenancePage from './maintenance/page';

export default function Layout({ children }: PropsWithChildren): ReactNode {
  const { value: isPartnerGateEnabled, isLoading } = useGate('essence');

  useAnalytics();

  if (isLoading) {
    return (
      <Loading className="z-[201] bg-gray-100" fullScreen svgClassName="w-40" />
    );
  }

  if (isPartnerGateEnabled) {
    return children;
  }

  return <MaintenancePage />;
}
