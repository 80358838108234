/**
 * Before sending to Segment Source:
 * - Transform & Enhance Segment payload
 * - Add checks & guardrails to all our Segment calls
 */

import type { MiddlewareFunction } from './types';
import withAnonymousId from './withAnonymousId';
import withCheckAnonymousId from './withCheckAnonymousId';
import withCheckHipaa from './withCheckHipaa';
import withEnhancedPayload from './withEnhancedPayload';
import withEventTypes from './withEventTypes';
import withTryCatch from './withTryCatch';

const sourceMiddlewares: MiddlewareFunction[] = [
  withAnonymousId,
  withEnhancedPayload,
  withEventTypes, // stops for bad event types
  withCheckAnonymousId, // stops when id matches cookie
  withCheckHipaa, // stops if required flags are missing
  withTryCatch, // should always be last
];

export type { MiddlewareFunction };
export default sourceMiddlewares;
