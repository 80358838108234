import { debugAnalytics } from '@/utils/debugAnalytics';

import sourceMiddlewares, {
  type MiddlewareFunction,
} from './sourceMiddlewares';

const setupAnalytics = (): void => {
  debugAnalytics(`setupAnalytics`, {
    data: {
      sourceMiddlewares: sourceMiddlewares.map(({ name }) => name),
    },
  });

  // add middleware for guardrails
  sourceMiddlewares.forEach((middleware: MiddlewareFunction) =>
    window.analytics.addSourceMiddleware(middleware),
  );
};

export default setupAnalytics;
