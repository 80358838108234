'use client';

import {
  OuraConsentManager,
  type OuraConsentManagerProps,
} from '@jouzen/consent-manager';
import { env } from 'next-runtime-env';
import { useEffect } from 'react';
import { useGate } from 'statsig-react';

import { getCookie } from '@/utils/cookie';
import { debugAnalytics } from '@/utils/debugAnalytics';

export const CONSENT_COOKIE_NAME = 'eop-tracking-preferences';
export const DISABLE_CONSENT_MANAGER_COOKIE = 'disable-segment';
export const SEGMENT_ANONYMOUS_ID_COOKIE = 'ajs_anonymous_id';

interface ConsentManagerProps {
  readonly locale: string;
}

const ConsentManager = ({ locale }: ConsentManagerProps) => {
  const SEGMENT_KEY = env('NEXT_PUBLIC_SEGMENT_KEY');

  const hasDisabledCookie =
    getCookie(DISABLE_CONSENT_MANAGER_COOKIE) === 'true';
  const { value: isGateEnabled } = useGate('eop_analytics_enabled');
  const isEnabled = isGateEnabled && !hasDisabledCookie;

  useEffect(() => {
    // only log once (unless things change)
    debugAnalytics(`Consent manager is ${isEnabled ? 'enabled' : 'disabled'}`, {
      data: { hasDisabledCookie, isGateEnabled },
    });
  }, [hasDisabledCookie, isEnabled, isGateEnabled]);

  if (!SEGMENT_KEY) {
    debugAnalytics(`SEGMENT_KEY not found`, {
      level: 'error',
      logToDatadog: 'ConsentManager',
    });

    return null;
  }

  return (
    <OuraConsentManager
      intlProps={{ locale }}
      segmentKey={SEGMENT_KEY}
      isEnabled={isEnabled}
      consentCookie={{ name: CONSENT_COOKIE_NAME }}
      loadOptions={
        {
          storage: { stores: ['localStorage', 'memory'] },
        } as OuraConsentManagerProps['loadOptions']
      }
    />
  );
};

export default ConsentManager;
