import { debugAnalytics } from '@/utils/debugAnalytics';
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from '@/utils/localStorage';

export const STORAGE_KEY = 'eop-segment-anonymous-id';

const getAnonymousId = (): string => {
  const existingId = readFromLocalStorage(STORAGE_KEY);

  if (existingId) {
    return existingId;
  } else {
    const newId = crypto.randomUUID();

    writeToLocalStorage(STORAGE_KEY, newId);
    debugAnalytics(`Creating new anonymousId`, {
      data: { anonymousId: newId },
    });

    return newId;
  }
};

export default getAnonymousId;
