import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/AkkuratLL-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/AkkuratLL-Light.woff2\",\"weight\":\"300\",\"style\":\"light\"}],\"variable\":\"--font-sans\"}],\"variableName\":\"AkkuratLL\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/PPEditorialNew-Ultralight.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"./fonts/PPEditorialNew-UltralightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"}],\"variable\":\"--font-serif\"}],\"variableName\":\"EditorialNew\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/head/prefetch.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/globals.css");
