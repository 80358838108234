'use client';

import type { AnalyticsSnippet } from '@segment/analytics-next';

import type { BaseDataLayer, PayloadItems } from './types';

interface DataLayer extends BaseDataLayer {
  eventPath?: string;
  eventAttributes?: PayloadItems;
}

declare global {
  interface Window {
    analytics?: AnalyticsSnippet;
    dataLayer?: DataLayer[];
  }
}
