'use client';

import { env } from 'next-runtime-env';
import { prefetchDNS } from 'react-dom';

const Prefetch = () => {
  const imgCdn = env('NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN');
  const ouraservicesUrl = env('NEXT_PUBLIC_ECOM_API_ENDPOINT');

  if (imgCdn) {
    prefetchDNS('https://' + imgCdn);
  }

  if (ouraservicesUrl) {
    prefetchDNS(ouraservicesUrl);
  }

  prefetchDNS('https://cdn.segment.com');
  prefetchDNS('https://prodregistryv2.org');

  return null;
};

export default Prefetch;
