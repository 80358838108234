import { debugAnalytics } from '@/utils/debugAnalytics';

import type { MiddlewareFunction } from './types';

const withCheckHipaa: MiddlewareFunction = ({ payload, next }): void => {
  const { context, properties } = payload.obj;

  if (context?.traits?.isHipaa && properties?.isHipaa) {
    next(payload);
  } else {
    debugAnalytics(`Required isHipaa flag missing`, {
      data: { payload },
      level: 'error',
      logToDatadog: 'withCheckHipaa',
    });
  }
};

export default withCheckHipaa;
