import { Address } from '@/types';
import { ApiError } from '@/types/Error';

export type ValidateEmailResponse = {
  error?: ApiError;
  eligible?: boolean;
  netsuiteOrderId?: string;
  netsuiteInternalId?: string;
  orderComplete?: boolean;
  skus?: string[];
  shippingAddress?: Address;
};

export async function validateEmail(email: string, emailToken: string | null) {
  const response = fetch('/api/validateEmail', {
    method: 'POST',
    body: JSON.stringify({ email, emailToken }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data as ValidateEmailResponse;
    });
  return response;
}
