import { debugAnalytics } from '@/utils/debugAnalytics';

import type { MiddlewareFunction } from './types';

const withTryCatch: MiddlewareFunction = ({ payload, next }): void => {
  const eventType = payload.obj.type;

  try {
    next(payload);
  } catch (error) {
    debugAnalytics(`Error sending ${eventType} event`, {
      data: {
        ...payload,
        error,
      },
      exception: true,
      level: 'error',
      logToDatadog: 'withTryCatch',
    });
  }
};

export default withTryCatch;
